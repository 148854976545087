import React, { Component } from "react"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"

class ProposicaoItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      proposicao: fetch(props.uri)
        .then(res => res.json())
        .then(json => {
          this.setState({ proposicao: json.dados })
          this.setState({ isLoading: false })
        }),
    }
  }

  getProposicaoDetail(uri) {
    this.setState({ isLoading: true })
    fetch(uri)
      .then(res => res.json())
      .then(json => {
        this.setState({ proposicao: json.dados })
        this.setState({ isLoading: false })
      })
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="row">
          <div className="col-md-12">
            <p class="spinner-border text-warning mx-auto" role="status">
              <span class="sr-only">Loading...</span>
            </p>
          </div>
        </div>
      )
    } else {
      //console.log(this.state.proposicao)
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          key={this.state.proposicao.id}
          href={this.state.proposicao.urlInteiroTeor}
          className="list-group-item w-100 list-group-item-action"
        >
          <div className="row">
            <div className="col-md-10">
              <div className="">
                <h6 className="mb-1 tag-title">{`${this.state.proposicao.descricaoTipo} ${this.state.proposicao.numero}/${this.state.proposicao.ano}`}</h6>
                <small className="font-italic">
                  {moment(this.state.proposicao.dataApresentacao).format(
                    "DD/MM/YYYY"
                  )}
                </small>
              </div>
              <p className="mb-1">
                {this.state.proposicao.ementaDetalhada
                  ? this.state.proposicao.ementaDetalhada
                  : this.state.proposicao.ementa}
              </p>
              <small>
                <strong>Status: </strong>
                {this.state.proposicao.statusProposicao.despacho}{" "}
              </small>
            </div>
            <div className="col-md-2  d-flex justify-content-center flex-column ">
              <p className="download-icon text-center">
                {/* <FontAwesomeIcon icon={["fas", "download"]} /> */}
              </p>
              <p className="text-center">Veja na íntegra</p>
            </div>
          </div>
        </a>
      )
    }
  }
}

export default ProposicaoItem
