import React, { Component } from "react"
import Layout from "../components/layout"
import { MetaData } from "../components/meta"
import queryString from "query-string"
import fetch from "node-fetch"
import ProposicaoItem from "../components/proposicao-item"

const temas = [
  {
    nome_grupo: "Administração Pública e Reforma Política",
    codTemas: [34, 74, 53, 70],
    categorias:
      "Política | Partidos e Eleições | Processo Legislativo e Atuação Parlamentar | Administração Pública | Finanças Públicas e Orçamento",
  },
  /*{
    nome_grupo: 'Agricultura',
    codTemas: [64],
    categorias: 'Agricultura | Pecuária | Pesca e Extrativismo'
  },*/
  {
    nome_grupo: "Direito, Meio Ambiente e Agricultura",
    codTemas: [42, 43, 44, 48, 51, 54, 67, 68, 76, 64],
    categorias:
      "Direito Civil e Processual Civil | Direito Penal e Processual Penal | Direitos Humanos e Minorias | Meio Ambiente e Desenvolvimento Sustentável | Energia | Recursos Hídricos e Minerais | Direito e Defesa do Consumidor | Direito Constitucional |  Agricultura | Pecuária | Pesca e Extrativismo",
  },
  {
    nome_grupo: "Economia e Desenvolvimento",
    codTemas: [55, 61, 66],
    categorias:
      "Relações Internacionais e Comércio Exterior | Viação | Transporte e Mobilidade | Indústria | Comércio e Serviços",
  },
  {
    nome_grupo: "Educação, Ciência e Tecnologia",
    codTemas: [64, 62, 85, 86],
    categorias:
      "Ciência | Tecnologia e Inovação | Ciências Exatas e da Terra | Ciências Sociais e Humanas",
  },
  {
    nome_grupo: "Esporte e Cultura",
    codTemas: [35, 37, 39, 60, 72],
    categorias:
      "Arte, Cultura e Religião | Comunicações, Esporte e Lazer | Turismo | Homenagens e Datas Comemorativas",
  },
  {
    nome_grupo: "Saúde",
    codTemas: [56],
    categorias: "Saúde",
  },
  {
    nome_grupo: "Segurança",
    codTemas: [57],
    categorias: "Segurança",
  },

  {
    nome_grupo: "Trabalho e Aposentadoria",
    codTemas: [52, 58],
    categorias: "Previdência e Assistência Social | Trabalho e Emprego",
  },
]

class DeputadoPage extends Component {
  constructor(props) {
    super(props)
    const tema_inicial = temas[0]


    
    this.state = {
      isLoading: true,
      codTema: tema_inicial.codTemas,
      nome_grupo: tema_inicial.nome_grupo,
      categorias: tema_inicial.categorias,
      proposicoes: [],
    }
  }

  updateProposicoes(codTema) {
    const options = {
      idDeputadoAutor: 73486,
      siglaTipo: ["PEC", "PL", "PDL", "PLP", "PRC"],
      ordem: "desc",
      ordenarPor: "ano",
      itens: 150,
      codTema: codTema,
    }

    const uri = `https://dadosabertos.camara.leg.br/api/v2/proposicoes?${queryString.stringify(
      options
    )}`

    this.setState({ isLoading: true })
    fetch(uri)
      .then(res => res.json())
      .then(json => {
        let proposicoes = json.dados.map(item => item.uri)
        proposicoes = Array.from(new Set(proposicoes))
        this.setState({ proposicoes: proposicoes, isLoading: false })
      })
  }

  componentDidMount() {
    this.updateProposicoes(this.state.codTema)
  }

  updateTemas(tema) {
    this.setState({
      codTema: tema.codTemas,
      nome_grupo: tema.nome_grupo,
      categorias: tema.categorias,
    })
    this.updateProposicoes(tema.codTemas)
  }

  render() {
    const location = {
      pathname: "/proposicoes/",
      search: "",
      hash: "",
    }
    return (
      <Layout>
        <MetaData location={location} type="series" />
        {/* <div className="row">
          <div className="col-12"><h2>#proposições</h2></div>
        </div> */}

        <div className="row mt-3">
          <div className="col-md-3 ">
            <div className="list-group">
              {temas.map((item, index) => (
                <button
                  key={index}
                  onClick={() => this.updateTemas(item)}
                  type="button"
                  className="list-group-item list-group-item-action"
                >
                  {item.nome_grupo}
                </button>
              ))}
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12">
                <div class="jumbotron jumbotron-fluid">
                  <div class="container">
                    <h1 class="display-4">{this.state.nome_grupo} </h1>
                    <p class="small">{this.state.categorias} </p>
                    <h6 className="pt-2">
                      <span class="badge badge-pill badge-danger mr-2 ml-2">
                        {this.state.proposicoes.length}
                      </span>{" "}
                      Proposições
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.isLoading ? (
                <div class="spinner-border mx-auto" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                this.state.proposicoes.map((uri, index) => (
                  <ProposicaoItem key={index} uri={uri} />
                ))
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

// export const query = graphql`
//   query Proposicoes {
//     allProposicoes(limit: 10) {
//       edges {
//         node {
//           id
//           siglaTipo
//           numero
//           ano
//           ementa
// 		  dataApresentacao(formatString: "DD MMMM, YYYY", locale: "pt-br")
// 		  statusProposicao{
// 			  despacho
// 			  descricaoSituacao
// 		  }
//         }
//       }
//     }
//   }
// `

export default DeputadoPage
